
export const EmailListingPublished = (props) => {
  const { firstName, listingTitle, listingId } = props;

  return (
    `
<head>
<style>
  div.legal-notice p{ 
    font-size:10px;
  }
  img{
    width:200px;
    text-align:center;
  } 
  div.logo{
    width:100%;
  }
</style>
</head>

<div class="logo">
  <img src="https://i.ibb.co/sj3qYfk/logo-principal-sin-claim.jpg" alt="logo-principal-sin-claim" border="0">
</div>

<p>Hola ${firstName}.</p>

<strong> Para poder completar la publicación de ${listingTitle}, necesitamos que nos envies, respondiendo a este email, la siguiente información:</strong>
<ol>
<li>Nombre completo del propietario</li>
<li>Dirección completa del local</li>
<li>Documento de identidad oficial (pasaporte o DNI) del propietario</li>
<li>Factura de servicios básicos (gas, luz, agua) o cualquier otra factura que acredite la propiedad o alquiler del local.</li>
</ol>

<p>Responde a este email adjuntando toda la documentación solicitada en formato .PDF, .JPG o .PNG.</p>

<p>El equipo de ÛUPO revisará la documentación, y en un plazo de <strong>24/48h</strong> te enviaremos un email confirmando la publicación de tu Pop Up. </p>
<p>Si no recibes la notificación en el plazo establecido, ponte en contacto con nosotros.</p>

<p><b>ID del Pop Up:</b> ${listingId}</p> <br>
<p>Muchas gracias,  el equipo de ÛUPO.</p>
<hr>
<div class="legal-notice">
   <p><strong>ADVERTENCIA LEGAL DE CONFIDENCIALIDAD</strong></p>
    <p>Este mensaje se dirige exclusivamente a la persona destinataria y puede contener información privilegiada o confidencial. Si no es Vd. dicha persona, queda notificado de que la utilización, divulgación y/o copia sin autorización está prohibida en virtud de la legislación vigente. Si ha recibido este mensaje por error, le rogamos que nos lo comunique inmediatamente por esta misma vía y proceda a su destrucción. Los datos personales que en esta comunicación aparecen, así como los que UUPOCOWORKING S.L.L mantiene de Vd. y de su empresa quedan sujetos a la legislación vigente en materia de protección de datos personales.</p>

  <p><strong>INFORMACION PROTECCION DE DATOS (Reglamento UE 2016/679, de 27 de abril de 2016).</strong></p>
    <p>Responsable: UUPOCOWORKING S.L.L. CIF: B72446073. Correo electrónico: hola@uupo.es Finalidad: Gestión administrativa de la comunicación y agenda de contactos. En caso de solicitud de oferta/presupuesto; elaboración, seguimiento y proceso de los servicios que se deriven. Legitimación: Interés legítimo, ejecución de un contrato o consentimiento revocable. Los datos se conservarán mientras no solicite su supresión, se mantenga la relación comercial o durante el tiempo necesario para cumplir con las obligaciones legales. Destinatarios: Los datos no se trasferirán a terceros países, ni se cederán a terceros, salvo obligación legal. Derechos: Podrá ejercer los derechos de acceso, rectificación, supresión, oposición, portabilidad, limitación o en su caso revocación de consentimiento enviando solicitud escrita a nuestra dirección postal o electrónica, acreditando su identidad. Tiene usted el derecho a presentar una reclamación ante la AEPD en el supuesto de que considere que se infringen sus derechos de protección de datos.</p>
</div>
        `
  )
  // return (
  //     <Html lang="es">
  //         <Head>
  //             <style>
  //                 {`
  //                 div.legal-notice p{
  //                                         font - size:10px;
  //                 }
  //                                     img{
  //                                         width:200px;
  //                                     text-align:center;
  //                 }
  //                                     div.logo{
  //                                         width:100%;
  //                 }
  //             `}
  //             </style>
  //         </Head>

  //         <Div class="logo">
  //             <Img src="https://i.ibb.co/sj3qYfk/logo-principal-sin-claim.jpg" alt="logo-principal-sin-claim" border="0"></Img>
  //         </Div>

  //         <P>Hola {{ firstName }}.</P>

  //         <strong> Para poder completar la publicación de {{ listingTitle }}, necesitamos que nos envies, respondiendo a este email, la siguiente información:</strong>
  //         <Ol>
  //             <Li>Nombre completo del propietario</Li>
  //             <Li>Dirección completa del local</Li>
  //             <Li>Documento de identidad oficial (pasaporte o DNI) del propietario</Li>
  //             <Li>Factura de servicios básicos (gas, luz, agua) o cualquier otra factura que acredite la propiedad o alquiler del local.</Li>
  //         </Ol>

  //         <P>Responde a este email adjuntando toda la documentación solicitada en formato .PDF, .JPG o .PNG.</P>

  //         <P>El equipo de ÛUPO revisará la documentación, y en un plazo de <strong>24/48h</strong> te enviaremos un email confirmando la publicación de tu Pop Up. </P>
  //         <P>Si no recibes la notificación en el plazo establecido, ponte en contacto con nosotros.</P>

  //         <P><b>ID del Pop Up:</b> {{ listingTitle }}</P> <Br />
  //         <P>Muchas gracias,  el equipo de ÛUPO.</P>
  //         <Hr>
  //             <Div class="legal-notice">
  //                 <P><strong>ADVERTENCIA LEGAL DE CONFIDENCIALIDAD</strong></P>
  //                 <P>Este mensaje se dirige exclusivamente a la persona destinataria y puede contener información privilegiada o confidencial. Si no es Vd. dicha persona, queda notificado de que la utilización, Divulgación y/o copia sin autorización está prohibida en virtud de la legislación vigente. Si ha recibido este mensaje por error, le rogamos que nos lo comunique inmediatamente por esta misma vía y proceda a su destrucción. Los datos personales que en esta comunicación aparecen, así como los que UUPOCOWORKING S.L.L mantiene de Vd. y de su empresa quedan sujetos a la legislación vigente en materia de protección de datos personales.</P>

  //                 <P><strong>INFORMACION PROTECCION DE DATOS (Reglamento UE 2016/679, de 27 de abril de 2016).</strong></P>
  //                 <P>Responsable: UUPOCOWORKING S.L.L. CIF: B72446073. Correo electrónico: hola@uupo.es Finalidad: Gestión administrativa de la comunicación y agenda de contactos. En caso de solicitud de oferta/presupuesto; elaboración, seguimiento y proceso de los servicios que se deriven. Legitimación: Interés legítimo, ejecución de un contrato o consentimiento revocable. Los datos se conservarán mientras no solicite su supresión, se mantenga la relación comercial o durante el tiempo necesario para cumplir con las obligaciones legales. Destinatarios: Los datos no se trasferirán a terceros países, ni se cederán a terceros, salvo obligación legal. Derechos: Podrá ejercer los derechos de acceso, rectificación, supresión, oposición, portabilidad, limitación o en su caso revocación de consentimiento enviando solicitud escrita a nuestra dirección postal o electrónica, acreditando su identidad. Tiene usted el derecho a presentar una reclamación ante la AEPD en el supuesto de que considere que se infringen sus derechos de protección de datos.</P>
  //             </Div>
  //         </Hr>

  //     </Html>
  // )
}