import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionContact.module.css';


const SectionContact = props => {
  const { rootClassName, className, latestListings } = props;
  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
      <div className={`${css.contactContent} ${classes}`}>
        <div className={css.containerContact}>
          <div className={css.content}>
            <div className={css.firstColumn}>
              <h2 className={css.title}>
                <FormattedMessage id="SectionContact.title" /> <FormattedMessage id="SectionContact.title2" />
              </h2>
              <p className={css.text}>
                <FormattedMessage id="SectionContact.text1" />
                <br />
                <FormattedMessage id="SectionContact.text2" /> <a href="mailto:hola@uupo.es">hola@uupo.es</a>
              </p>
            </div>
            {/* <div className={css.formColumn}>
              <form>
                <label className={css.label}>
                  NOMBRE:
                  <input type="text" name="name" className={css.inputForm} />
                </label>
                <label className={css.label}>
                  EMAIL:
                  <input type="email" name="email" className={css.inputForm} />
                </label>
                <label className={css.label}>
                  MENSAJE:
                  <textarea className={css.inputForm}/>
                </label>
                <button type = 'submit' className={css.buttonForm}>Enviar</button>
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
};

export default SectionContact;