import React, { Component, useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHeroUuper.module.css';

const SectionHeroUuper = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>

      <div className={`${css.heroUuperContent} ${classes}`}>
        <div className={css.contentText}>
          <div className={css.containerText}>
            <p className={classNames(css.heroUuperMainTitle, { [css.heroUuperMainTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHeroUuper.title" /> <br />
              <FormattedMessage id="SectionHeroUuper.title2" /> <br />
              <FormattedMessage id="SectionHeroUuper.title3" />
              <span className={css.line}></span>
            </p>
            <h1 className={classNames(css.heroUuperSubTitle, { [css.heroUuperSubTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHeroUuper.subtitle" /> <br />
              <FormattedMessage id="SectionHeroUuper.subtitle2" />
            </h1>
            <div className={css.contentButtons}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '',
                }}
                className={classNames(css.heroUuperButton, { [css.heroUuperButtonFEDelay]: mounted })}
              >
                <FormattedMessage id="SectionHeroUuper.browseButton" />
              </NamedLink>
              <NamedLink
                name="SignupPage"
                className={classNames(css.heroUuperButton2, { [css.heroUuperButton2FEDelay]: mounted })}
              >
                <FormattedMessage id="SectionHeroUuper.browseButton2" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionHeroUuper.defaultProps = { rootClassName: null, className: null };

SectionHeroUuper.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHeroUuper;
