import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import css from './SectionThreeUuper.module.css';

const SectionThreeUuper = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, isAuthenticated } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedClientSite = mounted && isAuthenticated;
  const classes = classNames(rootClassName || css.root, className)

  const authenticatedLinkLogin = authenticatedClientSite ? (
    <NamedLink
      className={classNames(css.threeUuperButton, { [css.threeUuperButtonFEDelay]: mounted })}
      name="ContactDetailsPage"
    >
      <FormattedMessage id="SectionThreeUuper.button" />
    </NamedLink>
  ) : <NamedLink
    className={classNames(css.threeUuperButton, { [css.threeUuperButtonFEDelay]: mounted })}
    name="SignupPage"
  >
    <FormattedMessage id="SectionThreeUuper.button" />
  </NamedLink>;

  return (
    <>
      <div className={classes}>
        <div className={css.containerColumns}>
          <div className={css.firstColumn}>
            <div className={css.contentColumns}>
              <div className={css.triangulo}></div>
              <h2 className={classNames(css.title, { [css.titleFEDelay]: mounted })}>
                <FormattedMessage id="SectionThreeUuper.text" />
              </h2>
              {authenticatedLinkLogin}
            </div>
          </div>
          <div className={css.secondColumn}></div>
        </div>
      </div>
    </>
  )
};

const { bool } = PropTypes;

SectionThreeUuper.defaultProps = { rootClassName: null, className: null };

SectionThreeUuper.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
};

export default SectionThreeUuper;