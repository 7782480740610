import React, { Component, useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';
import bg from '../../assets/imagen_banner_uupo.webp';
import arrow from '../../assets/arrow.png';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>

      <div className={`${css.heroContent} ${classes}`}>
        <div className={css.contentText}>
          <div className={css.containerArrow}>
            <img className={css.arrowLeft} src={arrow} alt="arrow" />
          </div>
          <div className={css.containerText}>
            <p className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHero.title" /> <br />
              <FormattedMessage id="SectionHero.title2" />
            </p>
            <h1 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHero.subTitle1" />
            </h1>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '',
              }}
              className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="SectionHero.browseButton" />
            </NamedLink>
            {/* <NamedLink
              name="LandingUuper"
              className={classNames(css.heroButton2, { [css.heroButton2FEDelay]: mounted })}
            >
              <FormattedMessage id="SectionHero.browseButton2" />
            </NamedLink> */}
          </div>
        </div>
        <div className={css.contentImg}>
          <img className={css.bannerHome} src={bg} alt="background" />
        </div>
      </div>
    </>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
