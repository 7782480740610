import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import logo from '../../assets/logo.svg';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaLinkedin,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteLinkedinPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToLinkedin = intl.formatMessage({ id: 'Footer.goToLinkedin' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink
      key="linkToLinkedin"
      href={siteLinkedinPage}
      className={css.icon}
      title={goToLinkedin}
    >
      <IconSocialMediaLinkedin />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  return [instragramLink, fbLink, twitterLink, linkedinLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <img src={logo} className={css.image} />
              </NamedLink>
            </div>
            <div className={css.linksRedes}>
              <div className={css.linksFooter}>
                <NamedLink name="LandingUuper" className={css.enlacePage}>
                  <FormattedMessage id="Footer.linkUuper" />
                </NamedLink>
                <NamedLink name="LandingUupo" className={css.enlacePage}>
                  <FormattedMessage id="Footer.linkUupo" />
                </NamedLink>
                <NamedLink name="FaqPage" className={css.enlacePage}>
                  <FormattedMessage id="Footer.linkFAQ" />
                </NamedLink>
              </div>
              <div className={css.linksFooter}>
                <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <FormattedMessage id="Footer.copyright" className={css.derechos} />
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </NamedLink>
              <FormattedMessage id="Footer.separador" />
              <NamedLink name="CookiesPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.cookiesPolicy" />
              </NamedLink>
              <FormattedMessage id="Footer.separador" />
              <NamedLink name="TermsOfServicePage" className={css.privacy}>
                <FormattedMessage id="Footer.avisoLegal" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
