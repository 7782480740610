import React, { useEffect } from 'react';
import css from './LanguageSwitcher.module.css';

import { Menu, MenuLabel, MenuContent, MenuItem } from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { handleSwitchLanguage } from '../../ducks/UI.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { IconArrowHead } from '../../components';

const LanguageSwitcherComponent = props => {
  const { onHandleSwitchNewLanguage, currentUser, language, onUpdateProfile, onMobile } = props;
  //set language to saved value if any
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  useEffect(() => {
    const savedLanguageMaybe =
      currentUser?.attributes?.profile?.protectedData?.language ||
      window.sessionStorage.getItem('sessionLanguage') ||
      language;
    if (language !== savedLanguageMaybe) {
      onHandleSwitchNewLanguage(savedLanguageMaybe);
    }
  }, []);

  const handleLanguageSwitch = async lang => {
    setMenuOpen(current => !current);
    await onHandleSwitchNewLanguage(lang);

    // if (typeof window !== 'undefined') {
    //   window.emitter.emit('sessionLanguage', lang);
    // }

    //update currentUser
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        protectedData: {
          language: lang,
        },
      });
    } else {
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('sessionLanguage', lang);
      }
    }
  };

  let menuLabel = "ESP";
  switch (language) {
    case 'en':
      menuLabel = "ENG"
      break;
    case 'es':
      menuLabel = "ESP";
      break;
  }

  return (
    <div className={onMobile ? css.langSwitchWrapperMobile : css.langSwitchWrapper}>
      <Menu isOpen={isMenuOpen} onToggleActive={setMenuOpen}>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <div className={css.menu}>
            {menuLabel}
            {isMenuOpen ?
              <IconArrowHead direction="up" />
              :
              <IconArrowHead direction="down" />
            }
          </div>
        </MenuLabel>

        <MenuContent className={onMobile ? css.profileMenuContentMobile : css.profileMenuContent}>
          <MenuItem key="en">
            <div className={css.labelWrapper} onClick={() => handleLanguageSwitch('en')}>
              <span className={css.mobileLabel}>
                ENG
              </span>
            </div>
          </MenuItem>
          <MenuItem key="es">
            <div className={css.labelWrapper} onClick={() => handleLanguageSwitch('es')}>
              <span className={css.mobileLabel}>
                ESP
              </span>
            </div>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { language } = state.UI;

  return {
    currentUser,
    language,
  };
};

const mapDispatchToProps = dispatch => ({
  onHandleSwitchNewLanguage: newLanguage => dispatch(handleSwitchLanguage(newLanguage)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LanguageSwitcher = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LanguageSwitcherComponent);

export default LanguageSwitcher;
