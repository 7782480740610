import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHeroUupo.module.css';
import video from '../../assets/landingUppo/uupo.webm';
import bg from '../../assets/background-1440.jpg';
import arrow from '../../assets/arrow.png';

const SectionHeroUupo = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, isAuthenticated } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedClient = mounted && isAuthenticated;
  const classes = classNames(rootClassName || css.root, className);

  const authenticatedLink = authenticatedClient ? (
    <NamedLink
      className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
      name="ContactDetailsPage"
    >
      <FormattedMessage id="SectionHeroUupo.browseButton" />
    </NamedLink>
  ) : <NamedLink
    className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
    name="SignupPage"
  >
    <FormattedMessage id="SectionHeroUupo.browseButton" />
  </NamedLink>;

  const [youtubeID] = useState('f7o-u153zGQ')

  return (
    <>
      <div className={`${css.heroContent} ${classes}`}>
        <div className={css.contentText}>
          <div className={css.containerArrow}>
            <img className={css.arrowLeft} src={arrow} alt="arrow" />
          </div>
          <div className={css.containerText}>
            <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHeroUupo.title" /> <br />
              <FormattedMessage id="SectionHeroUupo.title2" />
            </h1>
            <p className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHeroUupo.subTitle1" /> <br />
              <FormattedMessage id="SectionHeroUupo.subTitle2" />
            </p>
            {authenticatedLink}
          </div>
        </div>
        <div className={css.contentImg}>
          <iframe src="https://www.youtube.com/embed/HPvCeQIyq5Y?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </>
  );
};

const { bool } = PropTypes;

SectionHeroUupo.defaultProps = { rootClassName: null, className: null };

SectionHeroUupo.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
};

export default SectionHeroUupo;
