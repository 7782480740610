import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import img1 from '../../assets/img1.svg';
import img2 from '../../assets/img2.svg';
import css from './SectionThree.module.css';

const SectionThree = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
      <div className={`${css.classes} ${css.contentSection}`}>
        <div className={`${css.threeContent} ${css.containerTitle}`}>
          <div className={css.triangulo}></div>
          <p className={css.title}>
            <FormattedMessage id="SectionThree.title" />
          </p>
          <h2 className={css.subtitle}>
            <FormattedMessage id="SectionThree.subtitle1" /> <br />
            <FormattedMessage id="SectionThree.subtitle2" />
            <span className={css.line}></span>
          </h2>
          <div className={css.contentCards}>
            <div className={css.contentCard}>
              <img src={img1} className={css.image} />
              <FormattedMessage id="SectionThree.textCard1" />
            </div>
            <div className={css.contentCard}>
              <img src={img2} className={css.image} />
              <FormattedMessage id="SectionThree.textCard2" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default SectionThree;