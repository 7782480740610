import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import img1 from '../../assets/icon1.svg';
import img2 from '../../assets/icon2.svg';
import img3 from '../../assets/icon-tienda.svg';
import css from './SectionTwoUuper.module.css';

const SectionTwoUuper = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, latestListings } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
      <div className={css.classes}>
        <div className={`${css.twoUuperContent} ${css.containerTitle}`}>
          <div className={css.triangulo}></div>
          <p className={css.title}>
            <FormattedMessage id="SectionTwoUuper.title" />
          </p>
          <h2 className={css.subtitle}>
            <FormattedMessage id="SectionTwoUuper.subTitle" />
            <span className={css.line}></span>
          </h2>
          <div className={css.containerCards}>
            <div className={css.contentCard}>
              <img src={img1} className={css.image} />
              <FormattedMessage id="SectionTwoUuper.textCard1" />
            </div>
            <div className={css.contentCard}>
              <img src={img2} className={css.image} />
              <FormattedMessage id="SectionTwoUuper.textCard2" />
            </div>
            <div className={css.contentCard}>
              <img src={img3} className={css.image} />
              <FormattedMessage id="SectionTwoUuper.textCard3" />
            </div>
          </div>
        </div>
        <div className={css.sectionBackground}></div>
      </div>
    </>
  )
};

export default SectionTwoUuper;