import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import img1 from '../../assets/se-uuper.webp';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import css from './SectionOneUuper.module.css';

const SectionOneUuper = props => {

  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, latestListings } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
      <div className={`${css.uuperContent} ${classes}`}>
        <div className={css.containerUuper}>
          <div className={css.contentImgUuper}>
            <div>
              <img src={img1} className={css.image} />
            </div>
          </div>
          <div className={css.contentTextUuper}>
            <p className={classNames(css.titleOneUuper, { [css.titleOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.title" />
            </p>
            <h2 className={classNames(css.subtitleOneUuper, { [css.subtitleOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.subtitle1" />
            </h2>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text1" />
              <strong><FormattedMessage id="SectionOneUuper.text1.1" /></strong>
            </div>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text2" />
            </div>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text3" />
            </div>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text4" />
              <strong><FormattedMessage id="SectionOneUuper.text4.1" /></strong>
              <FormattedMessage id="SectionOneUuper.text4.2" />
            </div>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text5" />
            </div>
            <div className={classNames(css.textOneUuper, { [css.textOneUuperFEDelay]: mounted })}>
              <FormattedMessage id="SectionOneUuper.text6" />
            </div>
            <div className={css.contentButton}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    '',
                }}
                className={classNames(css.heroUuperButton, { [css.heroUuperButtonFEDelay]: mounted })}
              >
                <FormattedMessage id="SectionOneUuper.browseButton" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default SectionOneUuper;